<template>
  <div>
    <span class="svg-container">
      <svg-icon :icon-class="iconClass" />
    </span>
    <el-input
      v-model="currentValue"
      :type="inputType"
      :placeholder="placeholder"
      :name="name"
      :auto-complete="autoComplete"
      @input="handleInput"
      @keyup.enter.native="onEnterKey"
    />
    <span v-if="type === 'password'" class="show-pwd" @click="showPassword">
      <svg-icon :icon-class="inputType === 'password' ? 'eye' : 'eye-open'" />
    </span>
    <div v-if="showErrors" class="validation-errors">
      <div v-for="(error, index) in errors" :key="index" class="validation-error-item">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  components: {
    SvgIcon
  },
  props: {
    errors: {
      type: Array,
      default: () => []
    },
    iconClass: {
      type: String,
      default: 'password'
    },
    name: {
      type: String,
      default: 'password'
    },
    type: {
      type: String,
      default: 'password'
    },
    placeholder: {
      type: String,
      default: ''
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    autoComplete: {
      type: String,
      default: 'off'
    },
    value: {
      type: String,
      default: ''
    },
    onEnterKey: {
      type: Function,
      default: () => ({})
    }
  },
  data() {
    return {
      currentValue: '',
      inputType: 'password'
    };
  },
  computed: {
    parentFormItemStyle() {
      if (!this.showErrors) {
        return ``;
      }
      const totalErrors = this.errors.length;
      return `margin-bottom: ${totalErrors * 22 + 11}px;`;
    }
  },
  watch: {
    value(val) {
      this.currentValue = val;
    },
    parentFormItemStyle() {
      this.$emit('style', this.parentFormItemStyle);
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.currentValue);
    },
    showPassword() {
      if (this.type !== 'password') {
        return;
      } else if (this.inputType === 'password') {
        this.inputType = '';
      } else {
        this.inputType = this.type;
      }
    }
  }
};
</script>

<style lang="scss">
.validation-errors {
  display: block;
  color: #f56c6c;
  text-align: left;
  position: absolute;
  top: 100%;
  .validation-error-item {
    padding-top: 4px;
    position: relative;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
