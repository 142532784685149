<template>
  <table style="width: 100%; height: 100%">
    <tr>
      <td style="text-align: center; vertical-align: middle;">
        <div class="login-container">
          <el-form ref="form" :model="form" :rules="rules" class="login-form" auto-complete="off" label-position="left">
            <div class="title-container">
              <h3 class="title">
                {{ $t('newPassword.title') }}
              </h3>
              <lang-select v-if="multilingual" class="set-language" />
            </div>
            <div v-if="initialLoading">
              <div class="message-container">
                <i class="el-icon-loading"></i>
                <br />
                <span>{{ $t('common.loading') }}</span>
              </div>
            </div>
            <div v-if="!initialLoading">
              <el-collapse-transition>
                <div v-if="!invalidToken && !formDone" class="content-container">
                  <div class="description">
                    <span>{{ $t('newPassword.description') }}</span>
                  </div>
                  <div :style="passwordStyle" class="el-form-item-wrapper">
                    <el-form-item prop="password" :show-message="false">
                      <input-custom-errors
                        v-model="form.password"
                        :errors="errors"
                        :show-errors="validationActive"
                        :on-enter-key="handleSubmit"
                        :placeholder="$t('user.newPassword')"
                        @input="validationActive = true"
                        @style="val => (passwordStyle = val)"
                      />
                    </el-form-item>
                  </div>
                  <el-form-item prop="password2">
                    <input-custom-errors
                      v-model="form.password2"
                      :on-enter-key="handleSubmit"
                      :placeholder="$t('user.repeatPassword')"
                      @input="validationActive = true"
                    />
                  </el-form-item>
                  <el-button
                    :loading="loading"
                    type="primary"
                    style="width:100%;margin-bottom:30px;"
                    @click.native.prevent="handleSubmit"
                  >
                    {{ $t('newPassword.submit') }}
                  </el-button>
                  <el-collapse-transition>
                    <el-alert v-if="errorMsg" :title="errorMsg" type="error" :closable="false" />
                  </el-collapse-transition>
                </div>
              </el-collapse-transition>

              <div v-if="invalidToken">
                <div class="message-container">
                  <span>{{ $t('newPassword.invalidToken') }}</span>
                </div>
                <div class="footer">
                  <router-link :to="{ name: 'login' }">
                    <span>{{ $t('login.logIn') }}</span>
                  </router-link>
                </div>
              </div>

              <div v-if="formDone">
                <el-collapse-transition>
                  <div class="message-container">
                    <span>{{ $t('newPassword.done') }}</span>
                  </div>
                </el-collapse-transition>
                <div class="footer">
                  <router-link :to="{ name: 'login' }">
                    <span>{{ $t('login.logIn') }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import LangSelect from '@/components/LangSelect';
import { focusFirstInputInEl } from '@/utils/';
import InputCustomErrors from '@/components/DSE/InputCustomErrors';
import { validatePasswordWithPolicy } from '@/utils/validate';

export default {
  components: {
    LangSelect,
    InputCustomErrors
  },
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      errorMsg: '',
      form: {},
      formDone: false,
      passwordType1: 'password',
      passwordType2: 'password',
      validationActive: false,
      invalidToken: false,
      initialLoading: true,
      passwordStyle: ''
    };
  },
  computed: {
    multilingual() {
      return this.$store.getters['multilingual'];
    },
    errors() {
      const isValid = validatePasswordWithPolicy(this.form.password, this.pp);
      if (!isValid.isFromDatabase) {
        return [this.$i18n.t(`validation.error.isFromDatabase`)];
      }
      return Object.keys(isValid).reduce((acc, key) => {
        if (!isValid[key]) {
          if (key === 'length') {
            acc.push(this.$i18n.t(`validation.error.${key}`, { count: this.pp.minimumLength }));
          } else {
            acc.push(this.$i18n.t(`validation.error.${key}`));
          }
        }
        return acc;
      }, []);
    },
    pp() {
      return this.$store.state.authentication.passwordPolicy;
    },
    rules() {
      return {
        password: [
          {
            validator: (rule, value, callback) => {
              // If any value in validatePasswordWithPolicy is false
              if (Object.values(validatePasswordWithPolicy(value, this.pp)).some(e => !e)) {
                callback(new Error());
              } else {
                callback();
              }
            }
          }
        ],
        password2: [
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error(this.$i18n.t('validation.repeatPasswordMismatch')));
              } else {
                callback();
              }
            }
          }
        ]
      };
    }
  },
  mounted() {
    focusFirstInputInEl(this.$el);
  },
  async created() {
    this.$store.dispatch('getPasswordPolicy');
    try {
      const response = await this.$store.dispatch('validateTokenForNewPassword', this.token);
      this.invalidToken = !!response && !!response.request && response.request.response !== 'true';
    } catch (err) {
      this.invalidToken = true;
    } finally {
      this.initialLoading = false;
    }
  },
  methods: {
    async handleSubmit() {
      this.validationActive = true;
      this.loading = true;
      this.errorMsg = '';
      try {
        const valid = await this.$refs.form.validate();
        if (valid) {
          await this.$store.dispatch('changePasswordWithToken', {
            password: this.form.password,
            token: this.token
          });
          this.formDone = true;
        }
      } catch (err) {
        this.errorMsg = err && (err.message || this.$i18n.t('common.error'));
      } finally {
        this.loading = false;
      }
    },
    showPwd1() {
      this.showPwd(1);
    },
    showPwd2() {
      this.showPwd(2);
    },
    showPwd(id) {
      if (this[`passwordType${id}`] === 'password') {
        this[`passwordType${id}`] = '';
      } else {
        this[`passwordType${id}`] = 'password';
      }
    }
  }
};
</script>

<style src="@/styles/components/login/input.scss" lang="scss"></style>
<style rel="stylesheet/scss" src="@/styles/components/login/index.scss" lang="scss"></style>
<style lang="scss">
.el-form-item-wrapper {
  margin-bottom: 0px;
  -webkit-transition: margin-bottom 0.5s ease;
  transition: margin-bottom 0.5s ease;
}
</style>
